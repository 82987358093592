import React from 'react'
import MetaTags from 'react-meta-tags';
import { languages } from '../MetaTags'
import LanguagesSection from '../components/Languages'

const Languages = () => {
    return (
        <>
            <MetaTags>
                <title>{languages.title}</title>
                <meta name="description" content={languages.description} />
                <link rel="canonical" href={languages.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>OCR Converter For More Than 100+ Languages</h1>
                        <p>Convert text in your desired language</p>
                    </div>
                </div>
            </section>
            <LanguagesSection heading={'Select OCR Language you want to convert'} />
        </>
    )
}

export default Languages
