import React, { useState, useEffect } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, Typography, DialogContentText, DialogTitle, Slide } from '@mui/material'
import { apiDelete, apiGet, apiPost } from '../../functionsAPI'
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Users = () => {
    const user = JSON.parse(localStorage.getItem('oocUser'))
    const [openInvitation, setOpenInvitation] = useState(false);
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [userId, setUserId] = useState(null)
    const [email, setEmail] = useState(null)

    const [alertType, setAlertType] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)

    const [users, setUsers] = useState([])

    const getUsers = () => {
        apiGet('/enterprise/users')
            .then((res) => {
                setUsers(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [])


    const handleOpenInvitation = () => {
        setOpenInvitation(true);
    };

    const handleCloseInvitation = () => {
        setOpenInvitation(false);
    };

    const handleInviteNewUser = () => {
        if (email !== user.email) {
            const data = {
                email: email
            }
            apiPost('/enterprise/users/invite', data)
                .then((res) => {
                    getUsers()
                    handleCloseInvitation()
                    setAlertType('success')
                    setAlertMessage('An Invitation Email is sent to provided email, accept invitation to join Enterprise.')
                })
                .catch((err) => {
                    setAlertType('error')
                    setAlertMessage(err)
                    handleCloseInvitation()
                });
        } else {
            handleCloseInvitation()
            setAlertType('error')
            setAlertMessage('Cannot Invite User Itself!')
        }
    };

    const handleDeleteOpen = (userId) => {
        setDeleteToggle(true)
        setUserId(userId)
    }

    const handleDeleteClose = () => {
        setDeleteToggle(false)
        setUserId(null)
    }


    const deleteItem = (userId) => {
        apiDelete('/enterprise/users/' + userId)
            .then((res) => {
                getUsers()
                handleDeleteClose()
                setAlertType('success')
                setAlertMessage('User Removed Succesfully')
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage(err)
                handleCloseInvitation()
            });
    }

    return (
        <>
            <div className='text-end'>
                <Button variant='outlined' onClick={handleOpenInvitation}> + New User</Button>
            </div>
            <table className='table table-striped'>
                <thead>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Today's Scans</th>
                    <th>Total Scans</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    {users.length > 0 && users.map((item, index) => {
                        return <tr>
                            <td style={{ width: 'unset' }} >{index + 1}</td>
                            <td style={{ width: 'unset' }} >{`${item.first_name} ${item.last_name}`}</td>
                            <td style={{ width: 'unset' }} >{item.email}</td>
                            <td style={{ width: 'unset' }} >{item.today_scans}</td>
                            <td style={{ width: 'unset' }} >{item.total_scans}</td>
                            <td style={{ width: 'unset' }} ><DeleteIcon cursor='pointer' onClick={() => { handleDeleteOpen(item.id) }} /></td>
                        </tr>
                    })}
                </tbody>
            </table>
            {alertType ? (<Alert className='mt-4' severity={alertType}>{alertMessage}</Alert>) : ('')}
            <Dialog
                open={openInvitation}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseInvitation}
            >
                <DialogTitle component='h6'>Invite New User</DialogTitle>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    handleInviteNewUser()
                }
                }>
                    <DialogContent dividers>
                        <TextField
                            required
                            className='my-3'
                            label="Email"
                            type="email"
                            variant="outlined"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                        />
                        <DialogContentText className='mb-3'>
                            An invitation link will be sent to the above email address.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseInvitation}>Cancel</Button>
                        <Button variant="contained" color="primary" type='submit'>Invite User</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog open={deleteToggle} fullWidth maxWidth='sm' onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                <DialogTitle component='h6'>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(userId)} variant="contained" color="primary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Users
