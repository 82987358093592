import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { faq } from '../MetaTags'
import { Link } from 'react-router-dom';

const Faq = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{faq.title}</title>
                <meta name="description" content={faq.description} />
                <link rel="canonical" href={faq.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Frequently Asked Questions</h1>
                        <p>Here are some of the Frequently Asked Questions</p>
                    </div>
                </div>
            </section>
            <section id='faq'>
                <div className="container">
                    <h2 className='mt-unset'>1. Does Online OCR Converter store any documents on the server?</h2>
                    <p>We do not store any user documents and images on our servers. Please read <Link to="privacy-policy">Privacy Policy</Link> for more detail.</p>

                    <h2>2. Is this service available at all times?</h2>
                    <p>Yes. OCR converter is available 24/7</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>3. How many free scans are available per day?</h2>
                        <p>15 scans are free of cost per day.</p>

                        <h2>4. Are you providing an API for my Commercial project?</h2>
                        <p>Yes. We are providing API for commercial projects. Please <Link to="contact-us">Contact Us</Link> for API.</p>
                    </div>
                </div>
                <div className="container">
                    <h2>5. What is the difference between the FREE OCR and Subscription Plan?</h2>
                    <p>Subscribed users can get more scans per day as compared to the free plan.</p>

                    <h2>6. I have subscribed to the PRO version. If I somehow am unhappy with that, what do I have to do?</h2>
                    <p>Please <Link to="contact-us">Contact Us</Link>. If we can not fix the issue, we will refund you the amount of the current month in full.</p>
                </div>
                <div className="section-bg" >
                    <div className="container">
                        <h2 className='mt-unset'>7. Does Online OCR Converter store my credit card?</h2>
                        <p>No, We never store your payment information on our server. All Subscriptions and bills are managed by Stripe. You can cancel and update your subscription plan at any time from the customer portal.</p>
                    </div>
                </div>
                <div className="container">
                    <br /><br />
                    <p>If your question is not answered here... Please <Link to="contact-us">Contact Us</Link></p>
                </div>
            </section>
        </>
    )
}

export default Faq
