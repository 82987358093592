import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { formateDate } from '../../functions'
import { apiGet } from '../../functionsAPI'
import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const PaymentDetails = () => {

    const [invoices, setInvoices] = useState([])

    const getInvoices = () => {
        apiGet('/user-payments')
            .then((res) => {
                setInvoices(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getInvoices()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <h3>Payment Details</h3>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" className='payment'>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Plan</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell className='text-center'>Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice, index) => {
                            return (
                                <>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell>{invoice.plan}</TableCell>
                                        <TableCell>{invoice.reference}</TableCell>
                                        <TableCell>{formateDate(invoice.date)}</TableCell>
                                        <TableCell>{invoice.amount}</TableCell>
                                        <TableCell>{invoice.status}</TableCell>
                                        <TableCell align="center"><a href={invoice.invoice_pdf} target="_blank" className='pdf' rel="noreferrer"><PictureAsPdfIcon /></a></TableCell>
                                    </TableRow>
                                </>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default PaymentDetails
