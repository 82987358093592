import React from 'react'
import { homepage } from '../MetaTags'
import MetaTags from 'react-meta-tags';
import OcrWidget from '../components/OcrWidget'
import PlansAndPricing from '../components/PlansAndPricing'
import Languages from '../components/Languages'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom';
// import AppScreen from '../assets/images/app-screen.png'
import HowItWorks from '../components/HowItWorks';
import Features from '../components/Features'
import OurApi from '../components/OurApi';
import WhyChoose from '../components/WhyChoose';

const Homepage = () => {
    return (
        <>
            <MetaTags>
                <title>{homepage.title}</title>
                <meta name="description" content={homepage.description} />
                <link rel="canonical" href={homepage.canonical} />
            </MetaTags>
            <div className="main">
                <div className="child">
                    <div className="container">
                        <h1>Easily Convert Images to Text 100+ Languages - Free to Use! </h1>
                        <p>Effortless Conversations With Advanced OCR Technology</p>
                        <Link to={'/languages'}>
                            <Button className='mt-3' variant='contained' style={{ textTransform: 'capitalize', backgroundColor: 'white', color: '#273E6F', borderRadius: '15px' }}>Supported Languages</Button>
                        </Link>
                    </div>
                </div>
            </div>
            <section className='section-bg'>
                <div className="container">
                    <div className="ocr-widget">
                        <OcrWidget />
                    </div>
                </div>
            </section>
            <HowItWorks />
            <Languages viewAllButton={true} limit={40} />
            <WhyChoose />
            <section>
                <div className="container">
                    <h2>About Online OCR</h2>
                    <p>OnlineOCR Converter services that converts images, scans doc images text into editable files by using OCR Technology (Optical Character Recognition). Our OCR Services is available daily 15 scans free of use. There is no registration required to scan documents. Users can use JPG, PNG images for scene text. Users can select any available language.</p>
                    <p><strong>Your Data Is Safe:</strong> OnlineOCR Converter never saves your image data to our system. We are just extracting text from images. Checkout <Link to={'/privacy-policy'}>Privacy Policy</Link></p>
                </div>
            </section>
            <section className='section-bg' >
                <div >
                    <div className="container">
                        <h2>Frequently Asked Questions</h2>
                        <p>Here are some of the Frequently Asked Questions</p>
                    </div>
                </div>
                <div className="container">
                    <h5 className='mt-unset'>1. Does Online OCR Converter store any documents on the server?</h5>
                    <p>We do not store any user documents and images on our servers. Please read <Link to="privacy-policy">Privacy Policy</Link> for more detail.</p>
                    <h5>2. Is this service available at all times?</h5>
                    <p>Yes. OCR converter is available 24/7</p>
                    <br />
                    <Link to="faq"><Button variant="contained" className="btn-primary-2" style={{ textTransform: 'capitalize', }}>View All</Button></Link>
                </div>
            </section>
            {/* <section>
                <Container id='about'>
                    <Row>
                        <Col xl={4} lg={5} md={12} className='text-center'>
                            <img src={AppScreen} alt="App Screen" className='img-fluid app-screen' />
                        </Col>
                        <Col xl={8} lg={7} md={12}>
                            <h2>App is available for free on App Store and Play Store</h2>
                            <p>Do you find it difficult to get a text from an image? Here is a handy solution we have found for you, a free OCR Converter application you need to install.</p>
                            <p>This OCR Converter app, as the name suggests, will simply analyze the image which you have uploaded and transform it into editable text as an output.</p>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <OurApi />
        </>
    )
}

export default Homepage
