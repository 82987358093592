import React, { useEffect } from 'react'
import Login from './Login'
import Register from './Register'
import { Col, Container, Row } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import { signIn } from '../../MetaTags'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

export default function SignIn(props) {
    const { search } = useLocation()
    const parsed = queryString.parse(search)
    const token = localStorage.getItem('oocToken')

    useEffect(() => {
        window.scrollTo(0, 0)
        if (parsed.next === "subscribe" && token !== null) {
            props.history.push('/subscribe')
        } else if (token !== null) {
            props.history.push('/profile')
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{signIn.title}</title>
                <meta name="description" content={signIn.description} />
                <link rel="canonical" href={signIn.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Login or Sign Up Here</h1>
                    </div>
                </div>
            </section>
            <section id='auth'>
                <Container>
                    <Row className='sign-in'>
                        <Col lg={4} md={12}>
                            <Login {...props} />
                        </Col>
                        <Col lg={8} md={12}>
                            <Register />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
