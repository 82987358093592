import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { contactUs } from '../MetaTags'
import ContactComponent from '../components/ContactComponent';

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{contactUs.title}</title>
                <meta name="description" content={contactUs.description} />
                <link rel="canonical" href={contactUs.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Feel free to contact us</h1>
                        <p>We would love to hear from you</p>
                    </div>
                </div>
            </section>
            <ContactComponent />
        </>
    )
}

export default ContactUs
