import React, { useState, useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js';
import { apiPost } from '../../functionsAPI';
import { Alert, Button } from '@mui/material';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

const CheckOut = (props) => {
    const { search } = useLocation()
    const parsed = queryString.parse(search)

    const stripe = useStripe()
    const priceId = props.priceId

    const [error, setError] = useState(null)

    useEffect(() => {
        if (parsed.result && parsed.result === 'fail') {
            setError(<Alert className='mt-3' severity="error">Payment Failed. Please try again.</Alert>)
        }
        // eslint-disable-next-line
    }, [])

    const handleSubmit = () => {
        setError(null)
        const data = {
            priceId
        }
        apiPost('/create-checkout-session', data)
            .then((res) => {
                return res.data
            }).then(session => {
                stripe.redirectToCheckout(session).then(res => {
                })
            })
            .catch((err) => {
                setError(<Alert className='mt-3' severity="error">{err}</Alert>)
            });
    }

    return (
        <div>
            <Button disabled={!stripe} variant="outlined" className='mt-2' onClick={handleSubmit}>Continue Payment</Button>
            {error}
        </div>
    )
}

export default CheckOut
