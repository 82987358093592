import React from 'react'
import { CardTitle, Col, Row } from 'react-bootstrap'
import { Card, CardContent, CardMedia } from '@mui/material';
import ml_flexibility from '../assets/images/Multi-Language_Flexibility.png'
import Effortless_Text_Extractions from '../assets/images/Effortless_Text_Extractions.png'
import Global_Language_Support from '../assets/images/Global_Language_Support.png'
import Seamless_Copying from '../assets/images/Seamless_Copying.png'
import Enhanced_Image_Support from '../assets/images/Enhanced_Image_Support.png'
import Security_and_Privacy from '../assets/images/Security_and_Privacy.png'

export default function WhyChoose() {

    const cardsData = [
        {
            title: 'Effortless Text Extractions',
            description: 'Unlimited scans and no registrations required make extracting text from images to breeze.',
            image: Effortless_Text_Extractions,
        },
        {
            title: 'Global Language Support',
            description: 'Recognise text in over 100 languages, including poorly scanned or photographed pages.',
            image: Global_Language_Support,
        },
        {
            title: 'Multi-Language Flexibility',
            description: 'Easily extract text in multiple languages with our advanced recognition system.',
            image: ml_flexibility,
        },
        {
            title: 'Seamless Copying',
            description: 'Copy extracted text to your clipboard for quick and convenient use.',
            image: Seamless_Copying,
        },
        {
            title: 'Enhanced Image Support',
            description: 'Even low-resolution images are supported for accurate text recognition.',
            image: Enhanced_Image_Support,
        },
        {
            title: 'Security and Privacy',
            description: 'Your data is safe with usual files are removed from our servers after scanning.',
            image: Security_and_Privacy,
        },
    ]

    return (
        <>
            <section className='section-bg why-choose'>
                <div className="container">
                    <h2 className='center'>Why Choose Us ?</h2>
                    <Row className='text-center gx-5 gy-4'>
                        {cardsData.map((item) => {
                            return <Col lg={4} md={6} sm={12} >
                                <Card style={{ borderRadius: '15px', boxShadow: '4px 4px 6px 2px #cbcbcb' }}>
                                    <>
                                        <CardMedia className='m-3'>
                                            <img src={item.image} className='img-fluid' alt={item.image} />
                                        </CardMedia>
                                        <CardTitle>{item.title}</CardTitle>
                                        <CardContent className='text-muted'>{item.description}</CardContent>
                                    </>
                                </Card>
                            </Col>
                        })}
                    </Row>
                </div>
            </section>
        </>
    )
}
