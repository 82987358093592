import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import EnterprisePlan from './EnterprisePlan'
import MostPopularPlan from './MostPopularPlan'
import { Typography } from '@mui/material'
import { apiGet } from '../functionsAPI'
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify'
import { BATCH_FILE_LIMIT } from '../config'

export default function PlansAndPricing() {
    const [subscriptionsData, setSubscriptionsData] = useState([
        {
            "name": "Free",
            "amount": 0.0,
            "images": 5,
            "file_limit": 5
        },
        {
            "name": "Professional",
            "amount": 15.0,
            "images": 100,
            "file_limit": 20
        },
        {
            "name": "Enterprise",
            "amount": 2499.0,
            "images": 1000,
            "file_limit": 100
        },
        {
            "name": "Enterprise",
            "amount": 30.0,
            "images": 1000,
            "file_limit": 100
        },
        {
            "name": "Professional",
            "amount": 999.0,
            "images": 100,
            "file_limit": 20
        }
    ])

    const getAllSubscriptions = () => {
        apiGet('/subscriptions')
            .then((res) => {
                setSubscriptionsData(res.data)
            })
            .catch((err) => {
                console.log(err)
                toast.error(err)
            })
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        getAllSubscriptions()
        // eslint-disable-next-line
    }, [])

    const freePlan = subscriptionsData.find((plan) => plan.name === "Free");
    const professionalPlan = subscriptionsData.find((plan) => plan.name === "Professional");
    const enterprisePlan = subscriptionsData.find((plan) => plan.name === "Enterprise");

    const data = [
        {
            feature: 'Daily Scan Quota',
            free: freePlan.images,
            professional: professionalPlan.images,
            enterprise: enterprisePlan.images,
        },
        {
            feature: 'Individual Document Size Limit',
            free: freePlan.file_limit + ' MB',
            professional: professionalPlan.file_limit + ' MB',
            enterprise: enterprisePlan.file_limit + ' MB',
        },
        {
            feature: 'Scan Priority',
            free: 'Low',
            professional: 'Medium',
            enterprise: 'High',
        },
        {
            feature: 'low-confidence words Highlighting*',
            free: 'Fixed',
            professional: 'Fixed',
            enterprise: 'Dynamic',
        },
        {
            feature: 'Multiple Image Formats Support',
            free: true,
            professional: true,
            enterprise: true,
        },
        {
            feature: 'OCR In 100+ Languages',
            free: true,
            professional: true,
            enterprise: true,
        },
        {
            feature: 'Multi-language Document Scan (2 languages)',
            free: true,
            professional: true,
            enterprise: true,
        },
        {
            feature: 'Priortized Support',
            free: false,
            professional: true,
            enterprise: true,
        },
        {
            feature: 'PDF Scanning Feature**',
            free: false,
            professional: true,
            enterprise: true,
        },
        {
            feature: 'Bulk image scanning**',
            free: false,
            professional: true,
            enterprise: true,
        },
        // {
        //     feature: 'Googel Doc Support - Final Output of the Scan',
        //     free: false,
        //     professional: true,
        //     enterprise: true,
        // },
        {
            feature: 'Scan History Storage^',
            free: false,
            professional: false,
            enterprise: true,
        },
        {
            feature: 'Sub-user Invitation^^',
            free: false,
            professional: false,
            enterprise: true,
        }
    ]

    return (
        <>
            <section>
                <Container>
                    <h2 className='center'>Unlock Powerful OCR Solutions</h2>
                    <p className='text-center' style={{ textTransform: 'capitalize' }}>Affordable and effective OCR for every need</p>
                    <Row className='pricing justify-content-center'>
                        <Col xl={12} lg={12} md={12}>
                            <Row>
                                <Col md={4} className="my-auto">
                                    <div className="type-1">
                                        <h3>Free</h3>
                                        <div className="price">
                                            <span className="currency">$</span>
                                            <span className="amount">0</span>
                                            <p className='validity'></p>
                                        </div>
                                        <div className="features">
                                            <p>{freePlan.images} Scans/Day</p>
                                            <p>{freePlan.file_limit}MB Size Limit</p>
                                            <p>Low Priority</p>
                                            <p>OCR In 100+ Languages</p>
                                            <p>Multi-language Document Scan</p>
                                            <p>Multiple Image Formats Support</p>
                                        </div>
                                        <Link to="/sign-in" className="btn-action">Free Sign Up</Link>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <MostPopularPlan data={professionalPlan} />
                                </Col>
                                <Col md={4} className="my-auto">
                                    <EnterprisePlan data={enterprisePlan} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='mt-5'>
                        <Col>
                            <h2 component='h2'>
                                Compare Features By Plan
                            </h2>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Feature</th>
                                        <th scope="col" className='text-center'>&nbsp;&nbsp; Free &nbsp;&nbsp;&nbsp;</th>
                                        <th scope="col" className='text-center'>Professional &nbsp;</th>
                                        <th scope="col" className='text-center'>Enterprise</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => {
                                        return <tr>
                                            <td >{item.feature}</td>
                                            <td className='text-center'>{typeof item.free === 'boolean' ? (item.free && <CheckIcon style={{ color: '#2065EF' }} />) : item.free}</td>
                                            <td className='text-center'>{typeof item.professional === 'boolean' ? (item.professional && <CheckIcon style={{ color: '#2065EF' }} />) : item.professional}</td>
                                            <td className='text-center'>{typeof item.enterprise === 'boolean' ? (item.enterprise && <CheckIcon style={{ color: '#2065EF' }} />) : item.enterprise}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p>* - Highlighting words below specific level of accuracy with different color. <br />
                                                &nbsp;&nbsp;&nbsp; - Fixed : Highlight words above pre-set accuracy threshold (not user-controlled).<br />
                                                &nbsp;&nbsp;&nbsp; - Dynamic : Admin sets accuracy thresholds in profile menu.
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>** - During Batch and PDF scanning, each image and pdf page is counted as a separate scan respectively. <br />
                                                &nbsp;&nbsp;&nbsp;&nbsp; - Images limit per batch: {BATCH_FILE_LIMIT ?? 5}, PDF document pages limit : {BATCH_FILE_LIMIT ?? 5} </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>^ Professional Plan : Last 5 batch's scans are stored in history</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>^^ Enterprise users can invite any user (from the profile menu) to scan and work together on their account. Invited users will get all the benefits of the enterprise plan, but any scans they do will be deducted from the enterprise user's scan limit.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
