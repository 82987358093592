import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { Button, Dialog, DialogActions, DialogContent, TextField, DialogTitle, Autocomplete } from '@mui/material'
import { useHistory } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from "react-router-dom";
import { menu } from '../Menu';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { apiGet } from '../functionsAPI';
import { ToastContainer } from 'react-toastify';

const Header = () => {
    let location = useLocation().pathname;
    const history = useHistory();
    const [openInvitation, setOpenInvitation] = useState(false);
    const token = localStorage.getItem('oocToken')
    const profile = JSON.parse(localStorage.getItem('oocUser'))
    const activeProfile = localStorage.getItem('oocActiveProfile')
    const parent = profile ? profile.parent ?? null : null
    const [loggedIn, setLoggedIn] = useState(false)
    const [sideMenu, setSideMenu] = useState(false)

    const users = profile ? [
        { ...profile, plan: profile.plan ? profile.plan.plan : 'Free' },
        { ...parent, plan: 'Enterprise' }
    ] : [];

    const [user, setUser] = useState(profile ?
        (activeProfile === 'Enterprise' ?
            { ...parent, plan: 'Enterprise' } :
            { ...profile, plan: profile.plan ? profile.plan.plan : 'Free' }) :
        {}
    );

    function checkUrl(url) {
        if (location === url) {
            return 'active'
        } else {
            return null
        }
    }

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setSideMenu({ sideMenu, 'left': open });
    };

    useEffect(() => {
        if (token !== null) {
            setLoggedIn(true)
            // getProfile()
        }
    }, [])

    const getProfile = () => {
        apiGet('/profile')
            .then((res) => {
                localStorage.setItem('oocUser', JSON.stringify(res.data))
                if (!localStorage.getItem('oocActiveProfile')) {
                    if (res?.data?.parent) {
                        localStorage.setItem('oocActiveProfile', 'Enterprise');
                    } else {
                        localStorage.setItem('oocActiveProfile', 'Self');
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const handleSwitchAccount = () => {
        setOpenInvitation(true);
        // getProfile()
    };

    const handleCloseInvitation = () => {
        setOpenInvitation(false);
    };


    const handleSwitchUser = (e) => {
        e.preventDefault();
        if (user.id === profile.id) {
            localStorage.setItem('oocActiveProfile', 'Self');
        } else if (user.id === parent.id) {
            localStorage.setItem('oocActiveProfile', 'Enterprise');
        }
        setOpenInvitation(false);
        window.location.reload()
        history.push('/');
    };

    return (
        <div id="header" class="header d-flex align-items-center fixed-top">
            <div className='container-fluid container-xl position-relative d-flex align-items-center'>
                {/* This is single toast container for whole site */}
                <ToastContainer />

                {/* Mobile Menu */}
                <SwipeableDrawer
                    open={sideMenu['left']}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        sx={{ width: 300 }}
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <div className="mobile-logo">

                        </div>
                        <Divider />
                        <List>
                            {menu.map((menu, index) => (
                                (menu.display === true || menu.display === undefined) &&
                                <Link key={index} to={menu.path}>
                                    <ListItem button >
                                        <ListItemIcon>
                                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                                        </ListItemIcon>
                                        <ListItemText primary={menu.label} />
                                    </ListItem>
                                </Link>
                            ))}
                            <ListItem button>
                                {loggedIn ? (<Link to="/profile" className="btn-auth">My Profile</Link>) : (<Link to="/sign-in" className="btn-auth">Login/Register</Link>)}
                            </ListItem>
                        </List>
                    </Box>
                </SwipeableDrawer>
                {/* END: Mobile Menu */}
                <Link to="/" className='mt-3 d-flex align-items-center me-auto'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="234.652" height="66.013" viewBox="0 0 234.652 66.013">
                        <g id="Group_8417" data-name="Group 8417" transform="translate(-283.104 -512.564)">
                            <path id="Path_13683" data-name="Path 13683" d="M374.3,515.508l-1.689-.816,1.342-2.128.347.125Z" fill="#fff" />
                            <path id="Path_13684" data-name="Path 13684" d="M374.3,578.577l-1.689-.815,1.342-2.128.347.124Z" fill="#fff" />
                            <g id="IMAGE" transform="translate(0 -0.716)">
                                <g id="Group_8413" data-name="Group 8413">
                                    <text id="Online" transform="translate(342.517 531.224)" fill="#264070" fontSize="14.58" fontFamily="RubikRoman-Regular, Rubik" style={{ isolation: 'isolate' }}><tspan x="0" y="0">Online</tspan></text>
                                </g>
                            </g>
                            <g id="IMAGE-2" data-name="IMAGE" transform="translate(0 -0.716)">
                                <g id="Group_8414" data-name="Group 8414">
                                    <text id="OCR" transform="translate(341.232 555.384)" fill="#36f" fontSize="24.383" fontFamily="RubikRoman-SemiBold, Rubik" fontWeight="600" style={{ isolation: 'isolate' }}><tspan x="0" y="0">OCR</tspan></text>
                                </g>
                            </g>
                            <g id="IMAGE-3" data-name="IMAGE" transform="translate(0 -0.716)">
                                <text id="converter" transform="translate(393.756 554.939)" fill="#264070" fontSize="26.069" fontFamily="Rubik-Light, Rubik" fontWeight="300"><tspan x="0" y="0">converter</tspan></text>
                            </g>
                            <g id="Group_8416" data-name="Group 8416">
                                <path id="Path_13685" data-name="Path 13685" d="M284.9,522.934h-1.753v-7.387h7.388c0,.528.014,1.054-.017,1.578,0,.069-.24.179-.368.18-1.476.013-2.952,0-4.429.01-.943.006-.815-.133-.819.83-.008,1.421,0,2.843,0,4.264Z" transform="translate(-0.002 -0.002)" fill="#264070" />
                                <path id="Path_13686" data-name="Path 13686" d="M329.978,522.923h-1.76v-.478c0-1.557-.009-3.115.008-4.673,0-.354-.1-.473-.462-.469-1.586.017-3.17,0-4.756.014-.344,0-.463-.107-.435-.447a6.113,6.113,0,0,0,0-.942c-.026-.322.087-.423.414-.421,2.036.013,4.072.007,6.107.007h.884Z" transform="translate(-1.951)" fill="#264070" />
                                <path id="Path_13687" data-name="Path 13687" d="M284.9,553.635v.544q0,2.234,0,4.469c0,.632,0,.632.611.632h4.993v1.754h-7.374c-.009-.151-.022-.284-.022-.417,0-2.173.007-4.346-.008-6.519,0-.349.073-.5.451-.469C283.985,553.661,284.421,553.635,284.9,553.635Z" transform="translate(0 -1.884)" fill="#264070" />
                                <path id="Path_13688" data-name="Path 13688" d="M329.943,561.042H322.58c0-.529-.017-1.046.018-1.558.006-.078.251-.2.387-.2,1.585-.014,3.17-.021,4.755,0,.375,0,.491-.1.487-.478-.02-1.585,0-3.169-.014-4.755,0-.317.08-.446.414-.42.367.028.739-.011,1.105.019.1.008.263.161.264.247.01,2.35,0,4.7,0,7.048C329.991,560.969,329.969,560.992,329.943,561.042Z" transform="translate(-1.951 -1.885)" fill="#264070" />
                                <g id="Group_8415" data-name="Group 8415" transform="translate(287.026 524.228)">
                                    <path id="Path_13689" data-name="Path 13689" d="M312.1,537.436c4.257-.024,8.513-.012,12.771-.012h.489c0,.475.007.912-.008,1.348,0,.058-.1.141-.168.161a1.124,1.124,0,0,1-.311.01h-8.7l-5.889,0h-9.322q-6.653,0-13.305.007c-.331,0-.456-.08-.428-.421s.007-.694.007-1.106h15.452c.47,0,.471,0,.471-.476,0-2.378-.007-4.756.007-7.134,0-.341-.106-.43-.435-.425q-2.035.024-4.071,0c-.277,0-.361.083-.354.355.017.58,0,1.16.009,1.74.006.227-.071.306-.3.305q-2.053-.011-4.106,0c-.226,0-.3-.084-.3-.305q.007-3.271,0-6.542a1.985,1.985,0,0,1,.037-.247h.34q12.318,0,24.637-.009c.307,0,.39.087.388.393q-.02,3.167,0,6.333c0,.279-.068.386-.366.383q-2-.02-4,0c-.279,0-.358-.093-.35-.362.017-.557,0-1.114.01-1.67.007-.259-.059-.372-.348-.369-1.393.013-2.784.008-4.176,0-.193,0-.326.006-.325.27.009,2.517-.019,5.251-.017,7.768m-4.759-9.535v.463c0,2.9,0,5.8,0,8.7,0,.29.086.379.374.374.823-.017,1.647-.02,2.471,0,.317.008.411-.085.409-.407-.011-2.9-.007-5.8-.007-8.7v-.416h7.9c0,.706-.008,1.378.009,2.049,0,.1.114.281.182.285.471.026.944.013,1.441.013.009-.166.022-.292.022-.417,0-1.067-.01-2.134.009-3.2.006-.334-.092-.433-.43-.433q-10.734.013-21.467.009c-.137,0-.273.016-.444.028,0,1.3,0,2.573.006,3.846,0,.055.087.157.134.158.493.011.987.008,1.511.008V527.9Z" transform="translate(-287.23 -524.681)" fill="#36f" />
                                    <path id="Path_13690" data-name="Path 13690" d="M312.551,550.649c0,.466-.007.872,0,1.277,0,.181-.069.255-.243.249-.092,0-.185,0-.278,0-3.375,0-6.75,0-10.124.006-.337,0-.5-.068-.454-.438a4.106,4.106,0,0,0,0-.764c-.019-.263.086-.337.341-.336,1.321.01,2.644,0,3.966,0h6.789Z" transform="translate(-287.933 -525.965)" fill="#264070" />
                                    <path id="Path_13691" data-name="Path 13691" d="M304.025,543.982h6.248v1.48h-6.248Z" transform="translate(-288.06 -525.635)" fill="#264070" />
                                    <path id="Path_13692" data-name="Path 13692" d="M303.993,548.839c0-.48-.015-.918.015-1.353,0-.068.216-.171.333-.173.9-.014,1.81-.008,2.714-.007.963,0,1.926.008,2.889,0,.264,0,.38.072.359.355-.021.3-.011.6,0,.9,0,.184-.049.279-.253.279C308.053,548.837,306.058,548.839,303.993,548.839Z" transform="translate(-288.058 -525.799)" fill="#264070" />
                                    <path id="Path_13693" data-name="Path 13693" d="M311.518,538.712" transform="translate(-288.431 -525.375)" fill="#36f" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </Link>
                <Button onClick={toggleDrawer(true)} className="d-block d-lg-none menuicon"><MenuIcon /></Button>
                <nav id="navbar" className="navbar right d-none d-lg-block">
                    <ul>
                        {menu.map((menu, index) => {
                            if (menu.display === true || menu.display === undefined) {
                                return (
                                    <li key={index} className={checkUrl(menu.path)}>
                                        <Link to={menu.path}>{menu.label}</Link>
                                    </li>
                                )
                            }
                        })}
                        <li>
                            {(loggedIn && token !== null) ? (<Link to="/profile" className="btn-auth"> {activeProfile === 'Enterprise' ? `Enterprise` : `My Profile`} {parent && <>&nbsp; <ChangeCircleIcon cursor='default' onClick={() => { handleSwitchAccount() }} /> </>} </Link>) : (<Link to="/sign-in" className="btn-auth">Login/Register</Link>)}
                        </li>
                    </ul>
                </nav>
                <Dialog
                    open={openInvitation}
                    keepMounted
                    onClose={handleCloseInvitation}
                    maxWidth='sm'
                    fullWidth
                >
                    <DialogTitle component='h6'>Switch Account</DialogTitle>
                    <form onSubmit={handleSwitchUser}>
                        <DialogContent dividers>
                            <Autocomplete
                                className='mt-4'
                                autoHighlight
                                disableClearable
                                options={users}
                                getOptionLabel={(option) => {
                                    if (option.plan === 'Enterprise') {
                                        return option.enterprise_name ? option.enterprise_name : `${option.first_name} ${option.last_name}`;
                                    }
                                    return `${option.first_name} ${option.last_name}` || "";
                                }}
                                value={user}
                                onChange={(e, value) => value ? setUser(value) : setUser(null)}
                                renderInput={(params) => <TextField {...params} label="Select Account" variant="outlined" />}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseInvitation}>Cancel</Button>
                            <Button variant="contained" color="primary" type='submit'>Switch</Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    )
}

export default Header;
