import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { apiDelete, apiPut } from '../functionsAPI';
import EditableWord from './EditableWord';
import ImageHighlight from './ImageHighlight';
import { Chip, Divider } from '@mui/material';
import { toast } from 'react-toastify';

const EditableTextArea = (props) => {
	const highlightColRef = useRef(null);
	const editColRef = useRef(null);
	const scaleRef = useRef(1);
	const darkState = localStorage.getItem('darkState');
	const [paragraphWords, setParagraphWords] = useState([]);
	const [highlightedWord, setHighlightedWord] = useState(null);

	useEffect(() => {
		setParagraphWords(props.data);
	}, [props.data]);

	const sortedWords = useMemo(() => {
		return paragraphWords.sort((a, b) => {
			if (a.page_num !== b.page_num) return a.page_num - b.page_num
			if (a.block_num !== b.block_num) return a.block_num - b.block_num;
			if (a.par_num !== b.par_num) return a.par_num - b.par_num;
			if (a.line_num !== b.line_num) return a.line_num - b.line_num;
			return a.word_num - b.word_num;
		});
	}, [paragraphWords]);

	const groupedWords = useMemo(() => {
		const groups = sortedWords.reduce((sortedList, word) => {
			const { page_num, block_num, par_num } = word;
			if (!sortedList[page_num]) sortedList[page_num] = {};
			if (!sortedList[page_num][block_num]) sortedList[page_num][block_num] = {};
			if (!sortedList[page_num][block_num][par_num]) sortedList[page_num][block_num][par_num] = [];
			sortedList[page_num][block_num][par_num].push(word);
			return sortedList;
		}, {});
		return Object.values(groups).map(block => Object.values(block));
	}, [sortedWords]);

	const handleWordEdit = async (wordId, editedText) => {
		try {
			props.setLoading(true);
			const data = { corrected_text: editedText };
			const res = await apiPut(`/articles/${props.articleId}/words/${wordId}`, data);
			setParagraphWords(prevWords => prevWords.map(word => (word.id === wordId ? res.data : word)));
		} catch (err) {
			console.error(err);
			toast.error(err);
		} finally {
			props.setLoading(false);
		}
	};

	const handleWordDelete = async (wordId) => {
		try {
			props.setLoading(true);
			await apiDelete(`/articles/${props.articleId}/words/${wordId}`);
			setParagraphWords(prevWords => prevWords.filter(word => word.id !== wordId));
		} catch (err) {
			console.log(err);
			toast.error(err);
		} finally {
			props.setLoading(false);
		}
	};

	const handleMouseDown = (event) => {
		event.preventDefault();
	};

	const highlightWord = (word) => {
		setHighlightedWord(word);
		handleScroll(word);
	};

	const handleScroll = (word) => {
		const scale = scaleRef.current;
		const parent = highlightColRef.current;
		const scrollableHeight = parent.scrollHeight;
		const scrollableWidth = parent.scrollWidth;
		const viewPortHeight = parent.offsetHeight;
		const viewPortWidth = parent.offsetWidth;
		if (parent.children[1]) {
			const imageHeight = parent.children[1].height;
			const imageWidth = parent.children[1].width;

			if (word) {
				let wordPositionX;
				let wordPositionY;
				// if (word.zone) {
				// 	wordPositionX = parseInt(word.zone.left) + word.left;
				// 	wordPositionY = parseInt(word.zone.top) + word.top;
				// } else {
				wordPositionX = word.left;
				wordPositionY = word.top;
				// }
				const relativeWordPositionX = wordPositionX / imageWidth;
				const relativeWordPositionY = wordPositionY / imageHeight;

				parent.scrollTop = ((scrollableHeight * relativeWordPositionY) - (viewPortHeight / 2)) * scale;
				parent.scrollLeft = ((scrollableWidth * relativeWordPositionX) - (viewPortWidth / 2)) * scale;
			}
		}
	};

	const previousWordMerge = (word) => {
		const index = paragraphWords.findIndex(unit => unit === word);
		const prevWord = paragraphWords[index - 1];
		setParagraphWords(prevWords => prevWords.filter(singleWord => singleWord.id !== prevWord.id));
		handleWordDelete(prevWord.id);
	};

	const postWordMerge = (word) => {
		const index = paragraphWords.findIndex(unit => unit === word);
		const postWord = paragraphWords[index + 1];
		setParagraphWords(prevWords => prevWords.filter(singleWord => singleWord.id !== postWord.id));
		handleWordDelete(postWord.id);
	};

	return (
		<section>
			<Container fluid>
				<Row>
					<Col ref={highlightColRef} className='border rounded py-3 my-3 d-flex align-items-start scrolable-editor' style={{ maxHeight: '80vh', overflowY: 'scroll' }}>
					{props.isImage ?
						props.image ? 
							highlightColRef.current && <ImageHighlight scaleRef={scale => scaleRef.current = scale} parentRef={highlightColRef.current} imgSrc={props.image} word={highlightedWord} /> 
						:
							<div style={{ display: !props.image ? "block" : "none" }} className="spinner-border text-primary align-self-center m-auto" />
					: 	props.pdfUrl ? 
							<object
								data={props.pdfUrl}
								type="application/pdf"
								width="100%"
								height="100%"
								title="Embedded PDF Viewer"
							/>
						: <div style={{ display: !props.image ? "block" : "none" }} className="spinner-border text-primary align-self-center m-auto" />
					}

						</Col>
					<Col
						ref={editColRef}
						className="border rounded p-3 my-3 mx-1 d-flex flex-column scrolable-editor"
						onMouseDown={handleMouseDown}
						onContextMenu={handleMouseDown}
						style={{ maxHeight: '80vh', overflowY: 'scroll' }}
					>
						{groupedWords.map((page, pageIndex) => {
							return <section key={pageIndex}>

								{props.pages > 1 && <Divider className='pb-4' > <Chip label={`Page ${pageIndex + 1}`} size="small" /></Divider>}

								{page.map((blockObject, blockIndex) => {

									const block = Object.values(blockObject)
									return <React.Fragment key={blockIndex + pageIndex}>
										{block.map((paragraph, paragraphIndex) => (
											<p key={paragraphIndex}>
												{paragraph.map(word => {
													return <EditableWord
														key={word.id}
														word={word}
														onWordEdit={handleWordEdit}
														conf={props.conf}
														darkState={darkState}
														highlightWord={highlightWord}
														previousWordMerge={previousWordMerge}
														postWordMerge={postWordMerge}
														articleWords={paragraphWords}
														parentRef={editColRef.current}
													/>
												})}
											</p>
										))}
									</React.Fragment>
								})}
							</section>
						})}
					</Col>
				</Row>
			</Container>
		</section >
	);
};

export default EditableTextArea;
