import React, { useEffect, useState } from 'react'
import { Alert, CircularProgress } from '@mui/material';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { apiPost } from '../../functionsAPI'
import MetaTags from 'react-meta-tags';
import { emailVerification } from '../../MetaTags'
import { Container } from 'react-bootstrap';

export default function EmailVerification(props) {
    const { search } = useLocation()
    const parsed = queryString.parse(search)

    const [loading, setLoading] = useState(false)
    const progress = loading ? (<CircularProgress size={30} />) : ('')

    const [alertType, setAlertType] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)

    const verifyEmail = (token) => {
        setLoading(true)
        const data = {
            token
        }
        apiPost('/verify', data)
            .then((res) => {
                setAlertType('success')
                setAlertMessage('Email verified successfully')
                setLoading(false)
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage('Link expired! Email verified failed..')
                setLoading(false)
            });
    }

    useEffect(() => {
        const token = parsed.token
        if (token === undefined) {
            props.history.push('/')
        } else {
            verifyEmail(token)
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <MetaTags>
                <title>{emailVerification.title}</title>
                <meta name="description" content={emailVerification.description} />
                <link rel="canonical" href={emailVerification.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Verifying your email</h1>
                    </div>
                </div>
            </section>
            <section>
                <Container>
                    {progress}
                    {alertType ? (<Alert severity={alertType}>{alertMessage}</Alert>) : ('')}
                </Container>
            </section>
        </div>
    );
}
