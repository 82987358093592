import React, { useEffect, useState } from 'react'
import Login from './Login'
import Register from './Register'
import { Col, Container, Row } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import { signIn } from '../../MetaTags'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { apiGet, apiPost } from '../../functionsAPI';
import { toast } from 'react-toastify';

export const Join = (props) => {
    const { search } = useLocation()
    const parsed = queryString.parse(search)
    const token = localStorage.getItem('oocToken')
    const [userExists, setUserExists] = useState(false)
    const [socialLogin, setSocialLogin] = useState(false)
    const { email, parent_id } = parsed

    useEffect(() => {
        window.scrollTo(0, 0)
        if (email && token === null) {
            checkUserAccount()
        } else if (email && token !== null) {
            const user = JSON.parse(localStorage.getItem('oocUser'))
            if (user.email === email) {
                registerSubUser()
            } else {
                localStorage.clear()
                checkUserAccount()
            }
        }
        // eslint-disable-next-line
    }, [])

    const registerSubUser = () => {
        apiPost(`/enterprise/${parent_id}/users/${email}`)
            .then((res) => {
                const user = res.data
                localStorage.setItem('oocUser', JSON.stringify(user))
                localStorage.setItem('oocActiveProfile', 'Self')
                props.history.push('/')
            })
            .catch((err) => {
                console.log(err)
                toast.error(err)
            });
    }

    const checkUserAccount = () => {
        apiGet('/users/' + email)
            .then((res) => {
                setUserExists(res.data.status)
                setSocialLogin(res.data.is_social)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err.message)
            });
    }

    return (
        <>
            <MetaTags>
                <title>{signIn.title}</title>
                <meta name="description" content={signIn.description} />
                <link rel="canonical" href={signIn.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Login or Sign Up Here</h1>
                    </div>
                </div>
            </section>
            <section id='auth'>
                <Container>
                    <Row className='sign-in justify-content-center'>
                        {userExists && <Col lg={4} md={12}>
                            <Login {...props} email={email} social_login={socialLogin} parent={parent_id} />
                        </Col>}
                        {!userExists && <Col lg={8} md={12}>
                            <Register email={email} parent={parent_id} />
                        </Col>}
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Join;