import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { useParams } from "react-router-dom"
import OcrWidget from '../components/OcrWidget'
import HowItWorks from '../components/HowItWorks'
import PlansAndPricing from '../components/PlansAndPricing'
import Languages from '../components/Languages'
import allLanguages from '../languages.json'

const Ocr = (props) => {
    let { languageURL } = useParams()
    const [language, setLanguage] = useState({ name: null })

    useEffect(() => {
        let lang = allLanguages.find(lang => lang.url === languageURL)
        if (lang) {
            setLanguage(lang)
        } else {
            props.history.push('/oops')
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>OCR In {language.name} Free Online OCR in {language.name} Image to text Convert Online in {language.name}</title>
                <meta name="description" content={`Online Free OCR for ${language.name} Language tool helps to extract ${language.name} language text from Image. Convert ${language.name} image to text Convert make easy.`} />
                <link rel="canonical" href={'https://onlineocrconverter.com/' + languageURL} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Free OCR in {language.name}</h1>
                        <p>Online Free OCR for {language.name} Language tool helps to extract {language.name} language text from Image. Convert {language.name} image to text Convert make easy.</p>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <OcrWidget value={language} />
                </div>
            </section>
            <HowItWorks />
            <PlansAndPricing />
            <Languages viewAllButton={true} bg={true} />
        </>
    )
}

export default Ocr
