import React, { useEffect } from 'react'
import MetaTags from 'react-meta-tags';
import { refundAndCancellationPolicy } from '../MetaTags'

const RefundAndCancellationPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <MetaTags>
                <title>{refundAndCancellationPolicy.title}</title>
                <meta name="description" content={refundAndCancellationPolicy.description} />
                <link rel="canonical" href={refundAndCancellationPolicy.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Refund And Cancellation Policy</h1>
                    </div>
                </div>
            </section>
            <section className="privacy container">
                <p>Online Ocr Converter believes in helping its customers as far as possible and has therefore a liberal cancellation policy. Under this policy:</p>
                <p>In case you feel that the service received does not work as the site or not as per details provided by Us, you must bring it to the notice within 24 hours of receiving the refund. The customer service team after looking into your complaint will take an appropriate decision.</p>
                <h2>Refund Policy</h2>
                <p>Online Ocr Converter does not offer refunds or re-credits for use/scanned services. Service returns will only be considered based on technical issues with the services at the sole discretion of Online Ocr Converter. The company reserves the right to first exercise resolving the technical issue that may include offline correction and provide services.</p>
            </section>
        </>
    )
}

export default RefundAndCancellationPolicy
