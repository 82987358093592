import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags';
import { languages } from '../MetaTags'
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import BatchList from './BatchList';
import FilesList from './FilesList';
import { Container } from 'react-bootstrap';
import { Typography, Button, Autocomplete, Drawer, Grid, IconButton, Tooltip, TextField, Breadcrumbs, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { apiGet } from '../functionsAPI';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { formateDate2 } from '../functions';

const useStyles = makeStyles((theme) => ({
    filters: {
        padding: '2rem',
        width: '450px',
    },
}));

const Scans = () => {
    const classes = useStyles()
    const profile = JSON.parse(localStorage.getItem('oocUser'))
    const activeProfile = localStorage.getItem('oocActiveProfile')
    const [openFilter, setOpenFilter] = useState(false)
    const toggleDrawer = () => {
        setOpenFilter(!openFilter)
    }
    const fileViewState = JSON.parse(localStorage.getItem('fileView'))
    const [fileView, setFileView] = useState(fileViewState)
    const [showClear, setShowClear] = useState(false)
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const [fromDate, setFromDate] = useState(null)
    const [singleDate, setSingleDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [showClearDates, setShowClearDates] = useState(false)
    const [openBatch, setOpenBatch] = useState(false)
    const [filters, setFilters] = useState(null)
    const [refresh, setRefresh] = useState(0)

    let plan;

    if (activeProfile === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Professional') {
        plan = 'Professional'
    } else {
        plan = 'Free'
    }

    const getUsers = () => {
        const activeProfile = localStorage.getItem('oocActiveProfile');
        const user = JSON.parse(localStorage.getItem('oocUser')); // Ensure oocUser is parsed as JSON

        // Determine the parent_id based on the active profile
        const parent_id = (activeProfile === "Enterprise") ? user.parent.id : user.id;

        apiGet(`/enterprise/${parent_id}/users`)
            .then((res) => {
                const enterpriseUser = {
                    id: res.data.id,  // Use the enterprise id
                    first_name: res.data.enterprise_name ?? res.data.first_name,  // Use enterprise name for the first name
                    last_name: res.data.enterprise_name ? '' : res.data.last_name,  // Leave last name empty for enterprise
                    email: res.data.email // Enterprise email
                };

                // Combine enterprise user with other users
                const usersList = [enterpriseUser, ...res.data.users];

                setUsers(usersList);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getSubscription()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (filters) {
            users.map((item) => {
                return item.id === filters.userId && setUser(item)
            })
        } else {
            users.map((item) => {
                return item.id === profile.id && setUser(item)
            })
        }
        // eslint-disable-next-line
    }, [users])

    useEffect(() => {
        if (singleDate || fromDate || toDate) {
            setShowClear(true)
        } else {
            setShowClear(false)
        }
    }, [singleDate, fromDate, toDate])

    const getSubscription = () => {
        apiGet('/user-subscription')
            .then((res) => {
                if (profile.parent && activeProfile === 'Enterprise') {
                    setFilters({ ...filters, userId: profile.id, parentId: profile.parent.id })
                    getUsers()
                } else if (res.data.plan === 'Enterprise') {
                    setFilters({ ...filters, userId: profile.id, parentId: profile.id })
                    getUsers()
                } else if (res.data.plan === 'Professional') {
                    setFilters({ ...filters, userId: profile.id, parentId: null })
                } else {
                    setFilters({ ...filters, userId: null, parentId: null })
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    const apllyFilter = () => {
        setFilters({ ...filters, userId: user ? user.id : null, from_date: fromDate ? formateDate2(fromDate) : null, to_date: toDate ? formateDate2(toDate) : null, date: singleDate ? formateDate2(singleDate) : null })
        toggleDrawer()
    }

    const fileViewToggle = (value) => {
        localStorage.setItem('fileView', value)
        setFileView(value)
    }

    const clearFilters = () => {
        setUser(null)
        setFromDate(null)
        setToDate(null)
        setSingleDate(null)
        setShowClearDates(false)
    }

    const handleSingleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setSingleDate(date)
        setFromDate(null)
        setToDate(null)
        setShowClearDates(true)
    }

    const handleFromDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFromDate(date)
        if (toDate === null) {
            setToDate(moment())
        }
        setSingleDate(null)
        setShowClearDates(true)
    }

    const handleToDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setToDate(date)
        setSingleDate(null)
        setShowClearDates(true)
    }

    const clearDates = () => {
        setFromDate(null)
        setToDate(null)
        setSingleDate(null)
        setShowClearDates(false)
    }

    return (
        <>
            <MetaTags>
                <title>{languages.title}</title>
                <meta name="description" content={languages.description} />
                <link rel="canonical" href={languages.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container d-flex align-items-center">
                        <div>
                            <h1 className='d-inline'>Scans</h1>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: 'white' }}>
                                {fileView ?
                                    <Typography >Files</Typography> :
                                    (openBatch ?
                                        <Link underline='hover' style={{ cursor: 'pointer' }} color='white' onClick={() => setOpenBatch(false)}>
                                            Batches
                                        </Link>
                                        :
                                        <Typography >Batches</Typography>)}
                                {openBatch && <Typography >Files</Typography>}
                            </Breadcrumbs>
                        </div>
                        <Button variant="contained" className='ms-auto me-3' color="primary" onClick={() => setRefresh(refresh + 1)} >
                            <ReplayIcon fontSize='small' className='me-1' /> Refresh
                        </Button>
                        <FormControlLabel
                            className='mx-3'
                            control={
                                <Switch
                                    checked={fileView}
                                    onChange={(e) => fileViewToggle(e.target.checked)}
                                    color="warning"
                                />
                            }
                            label={fileView ? <Typography variant='button' color='#fff'>File View</Typography> : <Typography variant='button' color='#fff'>Batch View</Typography>}
                            labelPlacement="start"
                        />

                        {!openBatch && <Button variant="contained" className='mx-3' color="primary" onClick={toggleDrawer} >
                            <FilterListIcon /> &nbsp; Filters
                        </Button>}
                    </div>
                </div>
            </section>
            <section style={{ paddingTop: '0' }}>
                <Container>
                    {filters && (fileView ? <FilesList filters={filters} refresh={refresh} /> : <BatchList openBatch={openBatch} setOpenBatch={setOpenBatch} filters={filters} fileViewToggle={fileViewToggle} refresh={refresh} />)}
                </Container>
            </section>
            <Drawer anchor='right' open={openFilter} onClose={toggleDrawer}>
                <div className={classes.filters}>
                    <Grid container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item >
                            <Grid
                                container
                                direction="row"
                                justifyContent='flex-end'
                                alignItems='flex-start'
                            >
                                <Grid item >
                                    <Tooltip title="Close"><IconButton onClick={toggleDrawer}><CloseIcon /></IconButton></Tooltip>
                                    &nbsp;
                                    {showClear ? (
                                        <Button variant="contained" color="error" className='m-2' onClick={clearFilters}>
                                            Reset
                                        </Button>
                                    ) : ''}
                                    <Button variant="contained" color="primary" className='m-2' onClick={() => apllyFilter()}>
                                        Apply
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        {plan === 'Enterprise' && <Autocomplete
                            className='mt-4'
                            autoHighlight
                            options={users}
                            getOptionLabel={(option) => `${option.first_name} ${option.last_name}` || ""}
                            value={user}
                            onChange={(e, value) => value ? setUser(value) : setUser(null)}
                            renderInput={(params) => <TextField {...params} label="Users" variant="outlined" />}
                        />}
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                fullWidth
                                label="Single Date"
                                inputVariant="outlined"
                                format="DD/MM/YYYY"
                                value={singleDate}
                                className="mt-4"
                                onChange={handleSingleDateChange}
                            />
                            <DatePicker
                                label="From Date"
                                inputVariant="outlined"
                                format="DD/MM/YYYY"
                                fullWidth
                                value={fromDate}
                                maxDate={toDate && toDate}
                                className="mt-4"
                                onChange={handleFromDateChange}
                            />
                            <DatePicker
                                label="To Date"
                                inputVariant="outlined"
                                format="DD/MM/YYYY"
                                fullWidth
                                value={toDate}
                                minDate={fromDate && fromDate}
                                className="mt-4"
                                onChange={handleToDateChange}
                            />
                        </LocalizationProvider>
                        <br />
                        {showClearDates ? (
                            <Button variant="contained" color='error' className='mt-4' onClick={clearDates}>
                                Clear dates
                            </Button>
                        ) : ''}
                        {/* </Grid> */}
                    </Grid>
                </div>
            </Drawer>
        </>
    )
}

export default Scans
