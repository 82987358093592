import React from 'react'
import MetaTags from 'react-meta-tags';
import { plansPricing } from '../MetaTags'
import PlansAndPricing from '../components/PlansAndPricing'

const PlansPricing = () => {
    return (
        <>
            <MetaTags>
                <title>{plansPricing.title}</title>
                <meta name="description" content={plansPricing.description} />
                <link rel="canonical" href={plansPricing.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Our Plans and Pricings</h1>
                        <p>Checkout our affordable plans which suits best on you</p>
                    </div>
                </div>
            </section>
            <PlansAndPricing />
        </>
    )
}

export default PlansPricing
