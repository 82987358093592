import React from 'react'
import { useState } from "react";
import selectedPlanContext from "./selectedPlanContext";

const SelectedPlanState = (props) => {
    const s1 = {
        currentPlan : 'Professional',
    }
    const [state,setState] = useState(s1)

    const update = (planName) => {
        setState({
            currentPlan: planName
        })
    }
    return (
        <selectedPlanContext.Provider value={{state, update}}>
            {props.children}
        </selectedPlanContext.Provider>
    )
}

export default SelectedPlanState