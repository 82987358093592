import React, { useContext, useEffect, useState } from 'react';
import CheckOut from './CheckOut'
import MetaTags from 'react-meta-tags';
import { STRIPE_KEY } from '../../config';
import { selectPlan } from '../../MetaTags'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Col, Container, Row } from 'react-bootstrap';
import MostPopularPlan from '../../components/MostPopularPlan';
import { apiGet, apiGetURL } from '../../functionsAPI'
import { PRICE_ID_PROFESSIONAL_IN, PRICE_ID_PROFESSIONAL_OTHER, PRICE_ID_ENTERPRISE_IN, PRICE_ID_ENTERPRISE_OTHER } from '../../config';
import selectedPlanContext from '../../selectedPlanContext';
import EnterprisePlan from '../../components/EnterprisePlan';
import { toast } from 'react-toastify';
const stripePromise = loadStripe(STRIPE_KEY);

const professionalPriceInr = {
    symbol: '₹',
    amount: '999',
}

const professionalPriceOther = {
    symbol: '$',
    amount: '15',
}

const enterprisePriceInr = {
    symbol: '₹',
    amount: '2499',
}

const enterprisePriceOther = {
    symbol: '$',
    amount: '30',
}

export default function SelectPlan(props) {
    const context = useContext(selectedPlanContext)
    const [symbol, setSymbol] = useState('₹')
    const [amount, setAmount] = useState(context.state.currentPlan === "Enterprise" ? 2499 : 999)
    const [priceId, setPriceId] = useState(context.state.currentPlan === "Enterprise" ? PRICE_ID_ENTERPRISE_IN : PRICE_ID_PROFESSIONAL_IN )
    const [subscriptionsData, setSubscriptionsData] = useState([])

    const getCountry = () => {
        apiGetURL('https://ipapi.co/json/')
            .then((res) => {
                const countryCode = res.data.country_code
                if(context.state.currentPlan === "Enterprise"){

                    if (countryCode === 'IN') {
                        setSymbol(enterprisePriceInr.symbol)
                        setAmount(enterprisePriceInr.amount)
                        setPriceId(PRICE_ID_ENTERPRISE_IN)
                    }
                    else {
                        setSymbol(enterprisePriceOther.symbol)
                        setAmount(enterprisePriceOther.amount)
                        setPriceId(PRICE_ID_ENTERPRISE_OTHER)
                    }

                }else{

                    if (countryCode === 'IN') {
                        setSymbol(professionalPriceInr.symbol)
                        setAmount(professionalPriceInr.amount)
                        setPriceId(PRICE_ID_PROFESSIONAL_IN)
                    }
                    else {
                        setSymbol(professionalPriceOther.symbol)
                        setAmount(professionalPriceOther.amount)
                        setPriceId(PRICE_ID_PROFESSIONAL_OTHER)
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        const token = localStorage.getItem('oocToken')
        if (token === null) {
            var location = '/'
            props.history.push(location)
        }
        getAllSubscriptions()
        getCountry();
        // eslint-disable-next-line
    }, [])

    const getAllSubscriptions = () => {
        apiGet('/subscriptions')
            .then((res) => {
                setSubscriptionsData(res.data)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err)
            })
    }

    const professionalPlan = subscriptionsData.find((plan) => plan.name === "Professional");
    const enterprisePlan = subscriptionsData.find((plan) => plan.name === "Enterprise");

    return (
        <div>
            <MetaTags>
                <title>{selectPlan.title}</title>
                <meta name="description" content={selectPlan.description} />
                <link rel="canonical" href={selectPlan.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Pricing Details</h1>
                    </div>
                </div>
            </section>
            <section id='subscribe'>
                <Container>
                    <Row>
                        <Col md={4} className="pricing">
                            {context.state.currentPlan === "Enterprise" ? <EnterprisePlan displayButton={false} data={enterprisePlan} /> : <MostPopularPlan displayButton={false} data={professionalPlan} />}
                        </Col>
                        <Col md={8} className="order-summary">
                            <div className="card">
                                <h3>Order Summary</h3>
                                <hr />
                                <table>
                                    <tr>
                                        <th>Plan</th>
                                        <td>Professional ( {symbol+amount}/Month )</td>
                                    </tr>
                                    <tr>
                                        <th>Terms</th>
                                        <td>Billed monthly</td>
                                    </tr>
                                    <tr>
                                        <th>Starting</th>
                                        <td>Today</td>
                                    </tr>
                                    <tr>
                                        <th>Total To Pay</th>
                                        <th>{symbol+amount}</th>
                                    </tr>
                                </table>
                                <Elements stripe={stripePromise}>
                                    <CheckOut priceId={priceId} />
                                </Elements>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    );
}
