const profile = JSON.parse(localStorage.getItem('oocUser'))
const activeProfile = localStorage.getItem('oocActiveProfile')

const showScans = profile ? ((profile.plan && profile.plan.plan === 'Enterprise') || (profile.plan && profile.plan.plan === 'Professional') || (profile.parent !== null && activeProfile === 'Enterprise')) ? true : false : false


export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'Scans',
        path: '/scans',
        display: showScans
    },
    {
        label: 'About',
        path: '/about'
    },
    {
        label: 'Plans/Pricing',
        path: '/plans-pricing'
    },
    {
        label: 'REST API',
        path: '/ocr-rest-api'
    },
    {
        label: 'Languages',
        path: '/languages'
    },
    {
        label: 'FAQ',
        path: '/faq'
    },
    {
        label: 'Contact Us',
        path: '/contact-us'
    }
]