import React, { useEffect, useState } from 'react'
import Table from '../components/Table';
import { apiDelete, apiGet } from '../functionsAPI';
import {
    Chip,
    IconButton,
    LinearProgress,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from '@mui/material';
import { formateDate } from '../functions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom'
import moment from 'moment';
import BatchFilesList from './BatchFilesList';

const BatchList = ({ openBatch, setOpenBatch, filters, refresh }) => {
    const [loading, setLoading] = useState(false)
    const activeProfile = localStorage.getItem('oocActiveProfile')
    const progress = loading ? (<LinearProgress />) : ('')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [batchId, setBatchId] = useState(null)
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [showData, setShowData] = useState(false)

    const dummyObject = {
        number: '0000000000',
        is_ready: true,
        created_at: moment(),
        action: (
            <div>
                <Link to={'#'}>
                    <Tooltip title="View Output">
                        <IconButton >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                </Link>
                <Tooltip title="Delete file">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )

    }

    const dummyData = []

    for (let index = 0; index < 10; index++) {
        dummyData.push(dummyObject)
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "number",
            label: "Batch Number",
            options: {
                sort: true,
            }
        },
        {
            name: "is_ready",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    let status
                    if (value) {
                        status = <Chip label="Complete" style={{ backgroundColor: "#E2FAED", color: "green" }} />
                    } else {
                        status = <Chip label="Processing" style={{ backgroundColor: "#dae9f2", color: "#0c69a9" }} />
                    }
                    return status
                }
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: formateDate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (data) => {
        const params = {
            sort_by: data._sortBy ?? 'all',
            order: data._order ?? 'all',
            start: data._start ?? 0,
            limit: data._limit ?? 10,
            search: data._search ?? 'all',
            parent_id: filters ? activeProfile === 'Enterprise' ? filters.parentId : filters.parentId === filters.userId ? null : filters.parentId : null,
            user_id: filters ? filters.userId ?? 'all' : 'all',
            date: filters.date ?? 'all',
            from_date: filters.from_date ?? 'all',
            to_date: filters.to_date ?? 'all',

        }
        const url = '/batch'
        apiGet(url, params)
            .then(response => {
                response.data.list.map(item => {
                    let action = (
                        <div>
                            <Tooltip title="View Output" >
                                <IconButton onClick={() => getBatchDetails(item.id)} disabled={!item.is_ready} >
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete file">
                                <IconButton onClick={() => { handleDeleteOpen(item.id) }}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                    item.action = action
                    return true
                })
                setCount(response.data.count)
                setTableData(response.data.list)
                setShowData(true)
                setLoading(false)
            }).catch(err => {
                console.log(err);
                setLoading(false);
            });
    }

    const changePage = (page) => {
        const start_ = limit * (page)
        const data = {
            _start: start_,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            file_status: 'Success'
        }
        getList(data)
        setStart(start_)
    }

    const sort = (sortOrder) => {
        const sortBy_ = sortOrder.name
        const order_ = sortOrder.direction
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy_,
            _order: order_,
            _search: search,
            file_status: 'Success'
        }
        getList(data)
        setSortBy(sortBy_)
        setOrder(order_)
    }

    const changeRowsPerPage = (limit_) => {
        const data = {
            _start: start,
            _limit: limit_,
            _sortBy: sortBy,
            _order: order,
            _search: search,
            file_status: 'Success'
        }
        getList(data)
        setLimit(limit_)
    }

    const onSearch = (search_) => {
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search_,
        }
        getList(data)
        setSearch(search_)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    useEffect(() => {
        if (!openBatch) {
            const data = {
                _start: 0,
                _limit: 10,
                _sortBy: null,
                _order: null,
                _search: null,
            }
            getList(data)
        }
        // eslint-disable-next-line
    }, [filters, refresh])

    const getBatchDetails = (id) => {
        setBatchId(id)
        fileViewToggle(true)
    }

    const fileViewToggle = (value) => {
        setOpenBatch(value)
    }

    const handleDeleteOpen = (batchId) => {
        setDeleteToggle(true)
        setBatchId(batchId)
    }

    const handleDeleteClose = () => {
        setDeleteToggle(false)
        setBatchId(null)
    }

    const deleteItem = (batchId) => {
        setLoading(true)
        apiDelete(`/batch/${batchId}`)
            .then(res => {
                // const message = 'Article Deleted.'
                // handleAlert(1, message)
                setDeleteToggle(false)
                setLoading(false)
                const data = {
                    _start: start,
                    _limit: limit,
                    _sortBy: sortBy,
                    _order: order,
                    _search: search,
                }
                getList(data)
            }).catch(error => {
                console.log(error)
                // handleAlert(0, 'Error while deleting file')
                setLoading(false)
            })
    }

    return (
        <>
            {!openBatch &&
                <>
                    {progress}
                    <Table
                        title="List Batches"
                        serverSide={true}
                        count={count}
                        columns={columns}
                        data={showData ? tableData : dummyData}
                        onTableChange={handleTableChange}
                        rowsPerPage={limit}
                    />
                    {progress}
                </>
            }
            {openBatch && <BatchFilesList batch_id={batchId} filters={filters} refresh={refresh} />}
            <Dialog maxWidth='sm' fullWidth open={deleteToggle} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                <DialogTitle component='h6'>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(batchId)} variant="contained" color="primary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BatchList
