import { LoadingButton } from '@mui/lab';
import LoginWithGoogle from './LoginWithGoogle';
import { Alert, Chip, CircularProgress, Divider } from '@mui/material';
import React, { useState } from 'react'
import { trim } from '../../functions'
import { apiPost } from '../../functionsAPI';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function SignIn(props) {
    const email_ = props.email ?? null
    const parent_ = props.parent ?? null
    const social_login = props.social_login ?? null
    const { search } = useLocation()
    const parsed = queryString.parse(search)
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<CircularProgress size={30} />) : ('')
    const [error, setError] = useState(false)

    const [email, setEmail] = useState(email_ ?? '');
    const [password, setPassword] = useState('');

    const onLogin = (e) => {
        e.preventDefault()
        setLoading(true)
        setError(false)
        const data = {
            email: trim(email),
            password: trim(password)
        }
        apiPost('/sign-in', data)
            .then((res) => {
                const user = res.data
                localStorage.setItem('oocToken', user.token)
                localStorage.setItem('oocUser', JSON.stringify(user))
                if (email_ && parent_) {
                    registerSubUser();
                }
                setLoading(false)
                var location = '/'
                if (parsed.next) {
                    location += parsed.next
                }
                props.history.push(location)
                window.location.reload()
            })
            .catch((err) => {
                toast.error(err)
                setLoading(false)
            });
    }

    const registerSubUser = () => {
        apiPost(`/enterprise/${parent_}/users/${email_}`)
            .then((res) => {
                const user = res.data
                localStorage.setItem('oocUser', JSON.stringify(user))
                localStorage.setItem('oocActiveProfile', 'Self')
                props.history.push('/')
                setLoading(false)
            })
            .catch((err) => {
                setError(err)
                setLoading(false)
            });
    }

    return (
        <>
            <div className="card">
                <h2>Login</h2>
                {email_ && <p>Log in to your account and switch to the enterprise account from the profile menu.</p>}
                {!social_login &&
                    <form onSubmit={onLogin}>
                        <input type="email" required className="form-control" value={email} disabled={email_} onChange={(e) => setEmail(e.target.value)} placeholder="Enter Email" />
                        <input type="password" required className="form-control" autoFocus={email_} value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter Password" />
                        <div className="text-end mt-2">
                            <Link to="forgot-password" className='forgot'>Forgot password?</Link>
                        </div>
                        <LoadingButton
                            type='submit'
                            loading={loading}
                            className="mt-2"
                            fullWidth
                            loadingIndicator="Authenticating..."
                            variant="contained"
                        >
                            Login
                        </LoadingButton>
                        <p className='mt-3 text-center'>
                            {progress}
                        </p>
                    </form>
                }
                {(email_ && social_login) &&
                    <>
                        <p className='text-center my-3'>{email}</p>

                        <Divider className='my-2'>
                            <LoginWithGoogle email={email_} parent={parent_} />
                        </Divider>
                    </>
                }
                {!email_ &&
                    <>
                        <Divider className='my-4'>
                            <Chip label="OR" variant="outlined" />
                        </Divider>
                        <LoginWithGoogle />
                    </>
                }
                {error ? (
                    <Alert severity="error">{error}</Alert>
                ) : ''}
            </div>
        </>
    )
}
