const defaultTitle = 'Free OCR: Convert Image to Text Convertor | Image to word Convertor'
const defaultDescription = 'Free Online OCR Vonvert is tool for extract text from image. OCR Online tools is free for Convert Image to Text. Its also support JPEG, PNG to Text Extract. Make easy Image to Word Convertor'
const siteUrl = 'https://onlineocrconverter.com/'

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl
}

export const about = {
    title: ' About Us | Online OCR Convertor',
    description: 'About Us | Online OCR Convertor',
    canonical: siteUrl + 'about'
}

export const plansPricing = {
    title: 'Our Plans and Pricings | Online OCR Convertor',
    description: 'Checkout our affordable plans which suits best on you | Online OCR Convertor',
    canonical: siteUrl + 'plans-pricing'
}

export const ocrRestApi = {
    title: 'OCR REST API | Online OCR Convertor',
    description: 'Get OCR REST API | Online OCR Convertor',
    canonical: siteUrl + 'ocr-api'
}

export const languages = {
    title: 'OCR Converter For More Than 100+ Languages | Online OCR Convertor',
    description: 'Convert text in your desired language | Online OCR Convertor',
    canonical: siteUrl + 'languages'
}

export const contactUs = {
    title: 'Feel free to contact us | Online OCR Convertor',
    description: 'We would love to hear from you | Online OCR Convertor',
    canonical: siteUrl + 'contact-us'
}

export const faq = {
    title: 'Frequently Asked Questions | Online OCR Convertor',
    description: 'Here are some of the Frequently Asked Questions | Online OCR Convertor',
    canonical: siteUrl + 'faq'
}

export const docs = {
    title: 'Documentation for REST API | Online OCR Convertor',
    description: 'Documentation for how to use OCR REST API | Online OCR Convertor',
    canonical: siteUrl + 'docs'
}

export const privacyPolicy = {
    title: 'Privacy Policy | Online OCR Convertor',
    description: 'Privacy Policy | Online OCR Convertor',
    canonical: siteUrl + 'privacy-policy'
}

export const termsAndConditions = {
    title: 'Terms And Conditions | Online OCR Convertor',
    description: 'Terms And Conditions | Online OCR Convertor',
    canonical: siteUrl + 'terms-and-conditions'
}

export const refundAndCancellationPolicy = {
    title: 'Refund And Cancellation Policy | Online OCR Convertor',
    description: 'Refund And Cancellation Policy | Online OCR Convertor',
    canonical: siteUrl + 'refund-and-cancellation-policy'
}

export const signIn = {
    title: 'Login or Sign Up Here | Online OCR Convertor',
    description: 'Login or Sign Up Here and start using our service | Online OCR Convertor',
    canonical: siteUrl + 'sign-in'
}

export const profile = {
    title: 'My Profile | Online OCR Convertor',
    description: 'Profile Details, Subscription Details, Payment Details | Online OCR Convertor',
    canonical: siteUrl + 'profile'
}

export const emailVerification = {
    title: 'Email Verification | Online OCR Convertor',
    description: 'Email Verification | Online OCR Convertor',
    canonical: siteUrl + 'verify'
}

export const selectPlan = {
    title: 'Select Plan | Online OCR Convertor',
    description: 'Select Plan | Online OCR Convertor',
    canonical: siteUrl + 'subscribe'
}
