import React, { useEffect, useState, useMemo } from 'react'
import Table from '../components/Table';
import { apiDelete, apiGet } from '../functionsAPI';
import {
    AppBar,
    IconButton,
    LinearProgress,
    Slide,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { formateDate } from '../functions';
import { Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ImageIcon from '@mui/icons-material/Image';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import DeleteIcon from '@mui/icons-material/Delete';
import ArticleIcon from '@mui/icons-material/Article';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import EditableTextArea from '../components/EditableTextArea'
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const BatchFilesList = ({ batch_id, filters, refresh }) => {
    const activeProfile = localStorage.getItem('oocActiveProfile')
    const profile = JSON.parse(localStorage.getItem('oocUser'))
    const [conf, setConf] = useState(75)
    const [loading, setLoading] = useState(false)
    const progress = loading ? (<LinearProgress />) : ('')

    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showData, setShowData] = useState(false)
    const [pages, setPages] = useState(null)
    // const [showClear, setShowClear] = useState(false)

    const [openContent, setOpenContent] = useState(false)
    const [content, setContent] = useState([])
    const [previewImage, setPreviewImage] = useState(null)
    const [isImage, setIsImage] = useState(false)
    const [pdfUrl, setPdfUrl] = useState(false)
    const [articleId, setArticleId] = useState(null)
    const [paragraphWords, setParagraphWords] = useState([])
    const [type, setType] = useState(null)
    const [deleteToggle, setDeleteToggle] = useState(false)
    const [articleName, setArticleName] = useState(null)

    let plan;

    if (activeProfile === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Enterprise') {
        plan = 'Enterprise'
    } else if (profile?.plan?.plan === 'Professional') {
        plan = 'Professional'
    } else {
        plan = 'Free'
    }

    const dummyObject = {
        name: '......... ',
        batch: {
            number: '.........'
        },
        created_at: moment(),
        action: (
            <div>
                <Tooltip title="View Output">
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Copy to Clipboard">
                    <IconButton>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Txt Downlaod">
                    <IconButton>
                        <ArticleIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Docx Download">
                    <IconButton>
                        <DownloadIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download File">
                    <IconButton>
                        <ImageIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete file">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const dummyData = []

    for (let index = 0; index < 10; index++) {
        dummyData.push(dummyObject)
    }

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "File Name",
            options: {
                sort: true,
            }
        },
        {
            name: "batch",
            label: "Batch Number",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value.number
                }
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: formateDate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (data) => {
        const params = {
            sort_by: data._sortBy ?? 'all',
            order: data._order ?? 'all',
            start: data._start ?? 0,
            limit: data._limit ?? 10,
            search: data._search ?? 'all',
            file_status: 'Success',
            batch_id: batch_id ?? 'all',
            user_id: filters ? filters.userId ?? 'all' : 'all',
            parent_id: filters ? activeProfile === 'Enterprise' ? filters.parentId : filters.parentId === filters.userId ? null : filters.parentId : null,
        }
        const url = '/articles'
        apiGet(url, params)
            .then(response => {
                response.data.list.map(item => {
                    let action = (
                        <div>
                            <Tooltip title="View Output">
                                <IconButton onClick={() => { getArticleDetails(item.id) }}>
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Copy to Clipboard">
                                <IconButton onClick={() => { getContent(item.id, 'copy', item.name) }}>
                                    <ContentCopyIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Txt Downlaod">
                                <IconButton onClick={() => { getContent(item.id, 'txt', item.name) }}>
                                    <ArticleIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Docx Download">
                                <IconButton onClick={() => { getContent(item.id, 'docx', item.name) }}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Download File">
                                <IconButton onClick={() => { viewFile(item.id) }}>
                                    <ImageIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete file">
                                <IconButton onClick={() => handleDeleteOpen(item.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                    item.action = action
                    return true
                })
                setCount(response.data.count)
                setTableData(response.data.list)
                setLoading(false)
                setShowData(true)
            }).catch(err => {
                console.log(err);
                // handleAlert(0, err)
                setLoading(false);
            });
    }

    const handleDeleteOpen = (articleId) => {
        setDeleteToggle(true)
        setArticleId(articleId)
    }

    const handleDeleteClose = () => {
        setDeleteToggle(false)
        setArticleId(null)
    }

    const changePage = (page) => {
        const start_ = limit * (page)
        const data = {
            _start: start_,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search,
        }
        getList(data)
        setStart(start_)
    }

    const sort = (sortOrder) => {
        const sortBy_ = sortOrder.name
        const order_ = sortOrder.direction
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy_,
            _order: order_,
            _search: search,
        }
        getList(data)
        setSortBy(sortBy_)
        setOrder(order_)
    }

    const changeRowsPerPage = (limit_) => {
        const data = {
            _start: start,
            _limit: limit_,
            _sortBy: sortBy,
            _order: order,
            _search: search,
        }
        getList(data)
        setLimit(limit_)
    }

    const onSearch = (search_) => {
        const data = {
            _start: start,
            _limit: limit,
            _sortBy: sortBy,
            _order: order,
            _search: search_,
        }
        getList(data)
        setSearch(search_)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    useEffect(() => {
        const data = {
            _start: 0,
            _limit: 10,
            _sortBy: null,
            _order: null,
            _search: null,
        }
        getList(data)
        //eslint-disable-next-line
    }, [filters, refresh])


    const handleClickOpenContent = () => {
        setOpenContent(true);
    };

    const handleCloseContent = () => {
        setOpenContent(false);
        setArticleName(null)
        setPages(null)
        setConf(75)
        setArticleId(null)
        setContent([])
        setPreviewImage(null)
        setIsImage(false)
        setPdfUrl(null)
    };

    const getArticleDetails = (id) => {
        setArticleId(id)
        setLoading(true)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setPages(res.data.pages)
                setArticleName(res.data.name)
                if (res.data.user) {
                    setConf(res.data.user.conf);
                } else {
                    const storedConf = localStorage.getItem('conf');
                    if (storedConf !== null) {
                        setConf(storedConf);
                    } else {
                        setConf(75);
                    }
                }
                handleClickOpenContent()
                setContent(res.data.words)
                fetchAndConvertToBlob(res.data.url)
                    .then(blob => {
                        console.log(blob)
                        if (blob) {
                            if (blob.type === "application/pdf") {
                                setIsImage(false)
                                setPdfUrl(res.data.url)
                            } else {
                                setPreviewImage(blob);
                                setIsImage(true)
                            }
                        } else {
                            console.log('Error while converting image to blob.')
                            toast.error('Error while converting image to blob.')
                        }
                    });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err)
                setLoading(false)
            });
    }

    async function fetchAndConvertToBlob(url) {
        try {
            // Fetch the resource at the URL
            const response = await fetch(url);

            // Check if the fetch was successful
            if (!response.ok) {
                throw new Error('Failed to fetch resource');
            }

            // Convert the fetched data to a Blob
            const blob = await response.blob();

            return blob;
        } catch (error) {
            // handleAlert(0, 'Error fetching or converting to Blob')
            return null;
        }
    }

    const getContent = (id, type, name) => {
        setType(type)
        setArticleName(name)
        setLoading(true)
        apiGet(`/articles/${id}/words`)
            .then((res) => {
                setParagraphWords(res.data.words)
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err)
                setLoading(false)
            });
    }

    const viewFile = (id) => {
        setLoading(true)
        apiGet(`/articles/${id}`)
            .then((res) => {
                const link = document.createElement('a');
                link.href = res.data.url;
                link.target = '_blank'
                link.click();
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
                toast.error(err)
                setLoading(false)
            });
    }

    const sortedWords = useMemo(() => {
        return paragraphWords.sort((a, b) => {
            if (a.block_num !== b.block_num) return a.block_num - b.block_num;
            if (a.par_num !== b.par_num) return a.par_num - b.par_num;
            if (a.line_num !== b.line_num) return a.line_num - b.line_num;
            return a.word_num - b.word_num;
        });
    }, [paragraphWords]);

    const groupedWords = useMemo(() => {
        const groups = sortedWords.reduce((acc, word) => {
            const { block_num, par_num } = word;
            if (!acc[block_num]) acc[block_num] = {};
            if (!acc[block_num][par_num]) acc[block_num][par_num] = [];
            acc[block_num][par_num].push(word);
            return acc;
        }, {});
        return Object.values(groups).map(block => Object.values(block));
    }, [sortedWords]);

    const arrangeWords = (groupedWords) => {
        var data = '';
        const groupedWordsArray = Object.values(groupedWords)
        groupedWordsArray.forEach((parNum) => {
            const parNumArray = Object.values(parNum)
            parNumArray.forEach((para) => {
                const paraArray = Object.values(para)
                paraArray.forEach((word) => {

                    data += word.corrected_text ?? word.generated_text
                    data += ' '
                })
                data += '\n\n'
            })
        });

        if (type === 'copy') {
            copyToClipboard(data);
        } else if (type === 'txt') {
            downloadTxt(data, articleName)
        } else if (type === 'docx') {
            downloadDoc(data, articleName)
        }
    };

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data)
        const message = 'Copied to clipboard.'
        toast.success(message)
    }

    useEffect(() => {
        if (groupedWords.length > 0) {
            arrangeWords(groupedWords)
        }
        //eslint-disable-next-line
    }, [groupedWords])

    const downloadTxt = (data, articleName) => {
        try {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/plain' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${articleName}.txt`)
            document.body.appendChild(link);
            link.click();
            link.remove()
            toast.success('txt file Downloaded')
        } catch (error) {
            toast.error(error);
        }
    }

    const downloadDoc = (data, articleName) => {
        try {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/msword' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${articleName}.docx`)
            document.body.appendChild(link);
            link.click();
            link.remove()
            toast.success('Doc file Downloaded')
        } catch (error) {
            toast.error(error);
        }
    }

    const deleteItem = (articleId) => {
        setLoading(true)
        apiDelete(`/articles/${articleId}`)
            .then(res => {
                const message = 'Article Deleted.'
                toast.success(message)
                setDeleteToggle(false)
                setLoading(false)
                const data = {
                    _start: start,
                    _limit: limit,
                    _sortBy: sortBy,
                    _order: order,
                    _search: search,
                }
                getList(data)
            }).catch(error => {
                console.log(error)
                toast.error(error)
                setLoading(false)
            })
    }

    return (
        <>
            {progress}
            <Table
                title="List Batch's Scans"
                serverSide={true}
                count={count}
                columns={columns}
                data={showData ? tableData : dummyData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
            {progress}
            <Dialog fullScreen open={openContent} onClose={handleCloseContent} TransitionComponent={Transition}>
                <AppBar >
                    <Toolbar>
                        <IconButton edge="start" onClick={handleCloseContent} aria-label="close">
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                        <Typography component="p" style={{ color: 'white' }} >
                            Content : {articleName}
                        </Typography>

                        <div className='ms-auto text-white'>
                            <Tooltip title="Copy to Clipboard">
                                <IconButton onClick={() => { getContent(articleId, 'copy', articleName) }}>
                                    <ContentCopyIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Txt Downlaod">
                                <IconButton onClick={() => { getContent(articleId, 'txt', articleName) }}>
                                    <ArticleIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Docx Download">
                                <IconButton onClick={() => { getContent(articleId, 'docx', articleName) }}>
                                    <DownloadIcon style={{ color: 'white' }} />
                                </IconButton>
                            </Tooltip>
                            {(plan === 'Enterprise' || plan === 'Professional') &&
                                <>
                                    <Tooltip title="Download File">
                                        <IconButton onClick={() => { viewFile(articleId) }}>
                                            {isImage ?
                                                <ImageIcon style={{ color: 'white' }} />
                                                : <PdfIcon style={{ color: 'white' }} />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete file">
                                        <IconButton onClick={() => handleDeleteOpen(articleId)}>
                                            <DeleteIcon style={{ color: 'white' }} />
                                        </IconButton>
                                    </Tooltip>
                                </>}
                        </div>
                    </Toolbar>
                    {progress}
                </AppBar>
                {progress}
                {content.length > 0 && <EditableTextArea isImage={isImage} pdfUrl={pdfUrl} image={previewImage} data={content} conf={conf} articleId={articleId} pages={pages} setLoading={setLoading} />}
                {progress}
            </Dialog>
            <Dialog maxWidth='sm' fullWidth open={deleteToggle} onClose={handleDeleteClose} className="form-action" aria-labelledby="form-dialog-title">
                <DialogTitle component='h6'>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure you want to delete?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(articleId)} variant="contained" color="primary">
                        Yes, Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default BatchFilesList
