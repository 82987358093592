import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { formateDate } from '../../functions'
import { apiDelete, apiGet } from '../../functionsAPI'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SubscriptionDetails = (props) => {
    const user = JSON.parse(localStorage.getItem('oocUser'))
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const [alertType, setAlertType] = useState(null)
    const [alertMessage, setAlertMessage] = useState(null)

    const [details, setDetails] = useState({
        plan: '',
        amount: '',
        images: '',
        start_date: '',
        end_date: ''
    })

    const getSubscriptionDetails = () => {
        apiGet('/user-subscription')
            .then((res) => {
                res.data.start_date = formateDate(res.data.start_date)
                res.data.end_date = formateDate(res.data.end_date)
                setDetails(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    useEffect(() => {
        getSubscriptionDetails()
        // eslint-disable-next-line
    }, [])


    const handleClickOpen = () => {
        setOpenConfirmation(true);
    };
    const handleCloseConfirmation = () => {
        setOpenConfirmation(false);
    };
    const handleCancelSubscription = () => {
        apiDelete('/subscription')
            .then((res) => {
                getSubscriptionDetails()
                handleCloseConfirmation()
                setAlertType('success')
                setAlertMessage('Subscription canceled successfully. Now switched to Free Plan')
                //This code is added as temporary solution for scans menu is not appearing/disaapearing as expected in header
                // when subscription is cancelled, localstorage is cleared so that user has to re-login
                localStorage.clear()
                props.history.push('/')
            })
            .catch((err) => {
                setAlertType('error')
                setAlertMessage('Something went wrong! Please try again.')
                handleCloseConfirmation()
            });
    };

    return (
        <>
            <h3>{user.first_name} {user.last_name}</h3>
            <p className='email'>{user.email}</p>
            <table>
                <tr>
                    <td>Plan</td>
                    <th>{details.plan} ( {details.amount}/Month )</th>
                </tr>
                <tr>
                    <td>Images</td>
                    <th>{details.images}/Day</th>
                </tr>
                <tr>
                    <td>Terms</td>
                    <th>Billed monthly</th>
                </tr>
                <tr>
                    <td>Start Date</td>
                    <th>{details.start_date}</th>
                </tr>
                <tr>
                    <td>End Date</td>
                    <th>{details.end_date}</th>
                </tr>
                <tr>
                    <td>Amount</td>
                    <th>{details.amount}</th>
                </tr>
            </table>
            {details.amount > 0 ? (
                <Button variant="outlined" color="error" onClick={handleClickOpen}>
                    Cancel Subscription
                </Button>
            ) : ''}
            {alertType ? (<Alert className='mt-4' severity={alertType}>{alertMessage}</Alert>) : ('')}
            <Dialog
                open={openConfirmation}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseConfirmation}
            >
                <DialogTitle className='model'>Are you sure?</DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Are you sure you want to cancel your subscription?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmation}>No</Button>
                    <Button variant="contained" color="primary" onClick={handleCancelSubscription}>Yes, I'm sure</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default SubscriptionDetails
