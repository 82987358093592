import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import SuccessImg from '../../assets/images/payment-success.svg'

export default function PaymentSuccess(props) {
    const { search } = useLocation()
    const parsed = queryString.parse(search)

    useEffect(() => {
        if (!parsed.session_id) {
            props.history.push('/')
        }
        //This code is added as temporary solution for scans menu is not appearing/disaapearing as expected in header
        // when subscription is cancelled, localstorage is cleared so that user has to re-login
        localStorage.clear()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>Payment Success</h1>
                        <p>Your payment to Online OCR Converter is successfull</p>
                    </div>
                </div>
            </section>
            <section className='text-center'>
                <img src={SuccessImg} alt="Payment Success" className='img-fluid' />
                <p className='mt-5'>You can check your Subscription Details under <Link to='profile'>My Profile</Link> Page</p>
            </section>
        </div>
    )
}
