import { Box, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react'
import ProfileDetails from './profile/ProfileDetails'
import SubscriptionDetails from './profile/SubscriptionDetails'
import PaymentDetails from './profile/PaymentDetails'
import ApiKey from './profile/ApiKey'
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { Col, Container, Row } from 'react-bootstrap';
import MetaTags from 'react-meta-tags';
import { profile } from '../MetaTags'
import Users from './profile/Users';
import Accuracy from './profile/Accuracy';
import { apiGet } from '../functionsAPI';

const Profile = (props) => {
    const [tab, setTab] = useState(0);
    const [plan, setPlan] = useState(null)

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (e, newTab) => {
        setTab(newTab);
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        getSubscriptionDetails()
        const token = localStorage.getItem('oocToken')
        if (token === null) {
            props.history.push('/sign-in')
        }
        // eslint-disable-next-line
    }, [])

    const getSubscriptionDetails = () => {
        apiGet('/user-subscription')
            .then((res) => {
                setPlan(res.data.plan)
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <MetaTags>
                <title>{profile.title}</title>
                <meta name="description" content={profile.description} />
                <link rel="canonical" href={profile.canonical} />
            </MetaTags>
            <section className="breadcrumbs">
                <div className="inner">
                    <div className="container">
                        <h1>My Profile</h1>
                        <p>Manage your profile settings here</p>
                    </div>
                </div>
            </section>
            <section id='profile'>
                <Container>
                    <Row>
                        <Col md={4}>
                            <div className="card">
                                <h2>My Account</h2>
                                <Tabs
                                    value={tab}
                                    onChange={handleTabChange}
                                    orientation="vertical">
                                    <Tab label="Profile Details" {...a11yProps(0)} />
                                    <Tab label="Subscription Details" {...a11yProps(1)} />
                                    <Tab label="Payment Details" {...a11yProps(2)} />
                                    <Tab label="API Key" {...a11yProps(3)} />
                                    {plan === "Enterprise" && <h2 className='mt-5 '>Enterprise Plan</h2>}
                                    {plan === "Enterprise" && <Tab label="Users" {...a11yProps(5)} />}
                                    {plan === "Enterprise" && <Tab label="Accuracy Level" {...a11yProps(6)} />}
                                </Tabs>
                            </div>
                        </Col>
                        <Col md={8}>
                            <div className="card content">
                                <TabPanel value={tab} index={0}>
                                    <ProfileDetails {...props} plan={plan} />
                                </TabPanel>
                                <TabPanel value={tab} index={1}>
                                    <SubscriptionDetails />
                                </TabPanel>
                                <TabPanel value={tab} index={2}>
                                    <PaymentDetails />
                                </TabPanel>
                                <TabPanel value={tab} index={3}>
                                    <ApiKey {...props} />
                                </TabPanel>
                                <TabPanel value={tab} index={5}>
                                    <Users {...props} />
                                </TabPanel>
                                <TabPanel value={tab} index={6}>
                                    <Accuracy {...props} />
                                </TabPanel>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
        </>
    )
}

export default Profile
