import React, { useEffect, useRef, useState, useLayoutEffect, useCallback } from 'react'
import { Button } from '@mui/material';
import debounce from 'lodash/debounce';

const usePressedKeys = () => {
    const [pressedKeys, setPressedKeys] = useState(new Set())

    useEffect(() => {
        const handleKeyDown = event => {
            setPressedKeys(prevKeys => new Set(prevKeys).add(event.key))
        }

        const handleKeyUp = event => {
            setPressedKeys(prevKeys => {
                const updatedKeys = new Set(prevKeys)
                updatedKeys.delete(event.key)
                return updatedKeys
            })
        }

        document.addEventListener("keydown", handleKeyDown)
        document.addEventListener("keyup", handleKeyUp)
        return () => {
            document.removeEventListener("keydown", handleKeyDown)
            document.removeEventListener("keyup", handleKeyUp)
        }
    }, [])
    return pressedKeys
}

const ImageHighlight = ({ imgSrc, word, scaleRef, parentRef }) => {
    const canvasRef = useRef(null)
    const [image, setImage] = useState(null)

    // size factor is for setting rectangle border and ocr sequence fonts relative sizes
    const relativeSize = useRef(1)

    // word highlight gap (between word and rectangle border)
    const gap = 4 * relativeSize.current
    const [loading, setLoading] = useState(true)

    const pressedKeys = usePressedKeys()
    const [scale, setScale] = useState(1)

    useEffect(() => {
        scaleRef(scale)
        // eslint-disable-next-line
    }, [scale])

    const handleLoadFile = () => {
        const reader = new FileReader()
        reader.onload = async () => {

            const img = new Image()
            img.onload = () => {
                setImage(img)
                if (parentRef) {
                    const parentWidth = parentRef.clientWidth
                    const imageWidth = img.width
                    const dScale = parentWidth / imageWidth
                    dScale < 1 && setScale(Math.min(Math.max(Math.floor(dScale * 100 - 1) / 100, 0.1), 5))
                }
            }
            img.src = reader.result

        }
        reader.readAsDataURL(imgSrc)
    }

    useEffect(() => {
        handleLoadFile()
        // eslint-disable-next-line
    }, [imgSrc])

    useLayoutEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        if (image) {
            setLoading(false)
            const imgRelativeWidth = image.width / 1000
            relativeSize.current = imgRelativeWidth
            canvas.width = image.width * scale
            canvas.height = image.height * scale

            context.scale(scale, scale)

            const lineWidth = 2 * relativeSize.current
            !word && context.clearRect(0, 0, canvas.width, canvas.height)
            context.drawImage(image, 0, 0)
            context.beginPath()
            context.strokeStyle = '#ee0000'
            context.lineWidth = lineWidth
            const zone_top = word ? word.zone ? parseInt(word.zone.top) : 0 : 0
            const zone_left = word ? word.zone ? parseInt(word.zone.left) : 0 : 0
            context.fillStyle = '#eedf0055'
            word && context.fillRect(word.left + zone_left - gap + (lineWidth / 2), word.top - gap + zone_top + (lineWidth / 2), word.width + (gap * 2) - (lineWidth / 2), word.height + (gap * 2) - (lineWidth / 2))
            word && context.strokeRect(word.left + zone_left - gap, word.top - gap + zone_top, word.width + (gap * 2), word.height + (gap * 2))
        }

        // eslint-disable-next-line
    }, [scale, word, image])

    useEffect(() => {

        const zoomFunction = event => {
            if (pressedKeys.has("Meta") || pressedKeys.has("Control")) {
                event.preventDefault()
                onZoom(event.deltaY * - 0.001)
            }
        }

        document.addEventListener("wheel", zoomFunction, { passive: false })
        return () => {
            document.removeEventListener("wheel", zoomFunction)
        }

        // eslint-disable-next-line
    }, [pressedKeys])

    // eslint-disable-next-line
    const onZoom = useCallback(
        debounce((zoom) => {
            if (zoom === 0.25) {

                setScale((prevZoom) => {
                    const newZoom = zoomIn(prevZoom)
                    return Math.min(Math.max(newZoom, 0.1), 5)
                })

            } else if (zoom === -0.25) {

                setScale((prevZoom) => {
                    const newZoom = zoomOut(prevZoom)
                    return Math.min(Math.max(newZoom, 0.1), 5)
                })

            } else {

                setScale((prevZoom) => Math.min(Math.max(prevZoom + zoom, 0.1), 5))

            }
        }, 50),
        []
    )

    const zoomIn = (prevZoom) => {
        switch (true) {
            case (prevZoom < 0.25):
                return 0.25
            case (prevZoom < 0.5):
                return 0.5
            case (prevZoom < 0.75):
                return 0.75
            case (prevZoom <= 1):
                return 1
            case (prevZoom > 1):
                return 1
            default:
                return 1
        }
    }

    const zoomOut = (prevZoom) => {
        switch (true) {
            case (prevZoom < 0.26):
                return 0.1
            case (prevZoom < 0.51):
                return 0.25
            case (prevZoom < 0.76):
                return 0.5
            case (prevZoom <= 1):
                return 0.75
            case (prevZoom > 1):
                return 1
            default:
                return 1
        }
    }


    return (
        <>
            <div style={{ display: loading ? "block" : "none" }} class="spinner-border text-primary align-self-center m-auto" />
            <canvas ref={canvasRef} style={{ display: loading ? "none" : "block", }} className="m-auto" />
            <div style={{ position: 'fixed', zIndex: 2, bottom: 10, padding: 10 }}>
                <Button className='p-0 mx-1' tabIndex={'-1'} variant='contained' onClick={() => onZoom(-0.25)}>-</Button>
                <Button className='p-0 mx-1' tabIndex={'-1'} variant='contained' onClick={() => setScale(1)}> {new Intl.NumberFormat("en-GB", { style: 'percent' }).format(scale)} </Button>
                <Button className='p-0 mx-1' tabIndex={'-1'} variant='contained' onClick={() => onZoom(0.25)}>+</Button>
            </div>
        </>
    )
}

export default ImageHighlight
